import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {IdTokenService} from "../service/id-token.service";
import {CandidateStateService} from "../service/candidate-state.service";
import {catchError, EMPTY, map} from "rxjs";

export const slotsGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const idTokenService = inject(IdTokenService);
  const candidateStateService = inject(CandidateStateService);

  if (route.queryParams && route.queryParamMap.get('id')) {
    let token = route.queryParamMap.get('id');
    if (!idTokenService.isTokenExpired(token)) {
      return authorization(token)
    }
  }
  router.navigate(['/error'])
  return false

  function authorization(token: string) {
    return candidateStateService.authorization(token).pipe(
      map((candidateInfo) => {
        localStorage.setItem("token", token);
        candidateStateService.setCandidateInfo(candidateInfo);
        return true;
      }), catchError((err, caught) => {
        router.navigate(['/error'])
        return EMPTY;
      })
    );
  }
};
