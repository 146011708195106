import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Injectable,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  CalendarCommonModule,
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarEventTitleFormatter,
  CalendarView,
  CalendarWeekModule,
  CalendarWeekViewBeforeRenderEvent
} from 'angular-calendar';
import {WeekViewHourSegment} from 'calendar-utils';
import {fromEvent, ReplaySubject, Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {addDays, addMinutes, addWeeks, formatDate, isMonday, previousMonday,} from 'date-fns';
import {AsyncPipe, DatePipe, JsonPipe, KeyValuePipe, NgClass, NgForOf, NgIf, NgStyle} from "@angular/common";
import {BsModalService} from "ngx-bootstrap/modal";
import {SlotsModalComponent} from "./slots-modal/slots-modal.component";
import {animate, AUTO_STYLE, state, style, transition, trigger} from "@angular/animations";
import {CandidateStateService} from "../service/candidate-state.service";
import {CandidateInfo} from "../dto/candidate-info";
import {CandidateSlotsDto} from "../dto/candidate-slots-dto";
import {EventDto} from "../dto/event-dto";
import {ErrorDTO} from "../dto/error-dto";
import {ToastrService} from "ngx-toastr";

function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  override weekTooltip(): string {
    return '';
  }
}

@Component({
  selector: 'app-candidate-slots',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    CalendarCommonModule,
    NgClass,
    CalendarWeekModule,
    NgForOf,
    JsonPipe,
    DatePipe,
    NgStyle,
    KeyValuePipe,
    AsyncPipe,
  ],
  templateUrl: './candidate-slots.component.html',
  styleUrl: './candidate-slots.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter
    }
  ],
  animations: [
    trigger('collapse', [
      state('false', style({display: 'flex', 'min-height': '100vh', visibility: AUTO_STYLE })),
      state('true', style({display: 'none', height: '0', visibility: 'hidden' })),
      transition('false => true', animate(400 + 'ms ease-in')),
      transition('true => false', animate(400 + 'ms ease-out'))
    ])
  ]
})
export class CandidateSlotsComponent implements OnInit {
  destroy: ReplaySubject<void> = new ReplaySubject<void>(1);
  viewDate = new Date();
  weekDays = 7;
  events: CalendarEvent[] = [];
  dragToCreateActive = false;
  weekStartsOn: 0 = 0;
  calendarView = CalendarView;
  userName = "Candidate";
  timezone: string;
  collapsed = true;
  candidateInfo: CandidateInfo;
  inProgress = false;
  styleElement: HTMLStyleElement;
  rangeLimit: Date;


  constructor(private cdr: ChangeDetectorRef,
              private modalService: BsModalService,
              private candidateStateService: CandidateStateService,
              private alert: ToastrService) {
    this.styleElement = document.createElement('style');
    document.head.appendChild(this.styleElement);
  }

  ngOnInit(): void {
    this.prepareDateLimit();
    this.getCandidateInfo();
    this.getEventsFromLocalStorage();
    this.logCandidatesAttributes();
    this.timezone = this.convertToGMT(Intl.DateTimeFormat().resolvedOptions().timeZone);
    if (localStorage.getItem('candidateSlotStartWorkFinished') != "true") {
      this.openModal("start-work");
    }
    this.weekDays = window.innerWidth <= 890 ? 3 : 7;
    this.viewDate = this.weekDays == 7 ?
      isMonday(new Date()) ? new Date() : previousMonday(new Date())
      : new Date();
  }

  prepareDateLimit() {
    this.rangeLimit = this.resetDateHours(addWeeks(new Date(), 3), 0);
  }

  getEventsFromLocalStorage() {
    if (localStorage.getItem("events")) {
      let candidateSlots: CandidateSlotsDto = JSON.parse(localStorage.getItem("events"));
      if (candidateSlots.candidateId == this.candidateInfo.candidateId) {
        candidateSlots.slots.forEach(event => {
          if (new Date(event.startDate).getTime() >= new Date().getTime()) {
            this.events.push(this.createEvent(new Date(event.startDate), new Date(event.endDate)))
          }
        })
      }
    }
  }

  getCandidateInfo(){
    this.candidateInfo = this.candidateStateService.getCandidateInfo();
    this.userName = this.candidateInfo.candidateName ? this.candidateInfo.candidateName :this.userName
  }

  convertToGMT(timezone: string) {
    const now = new Date();
    const options = { timeZone: timezone, timeZoneName: 'short' };
    return new Intl.DateTimeFormat('en-US', <Object>options).formatToParts(now)
      .find(part => part.type === 'timeZoneName').value;
  }

  openModal(type: string) {
    const initialState = {
      type: type,
      name: this.userName,
      callback: () => {
        if (type == "start-work") {
          localStorage.setItem('candidateSlotStartWorkFinished', "true")
        }
      }
    };

    const modalConfig = {
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: false,
      backdrop: 'static'
    }

    let modalParams = Object.assign({}, modalConfig, {initialState});
    this.modalService.show(SlotsModalComponent, <Object>modalParams);
  }

  validateAndStartDragToCreate(segment: WeekViewHourSegment, segmentElement: HTMLElement) {
    if (segment.date < new Date() || addMinutes(segment.date, 60) > this.rangeLimit) {
      return;
    }
    for (const event of this.events) {
      if (event.start < addMinutes(segment.date, 60) && event.end >=addMinutes(segment.date, 60)) {
        return;
      }
    }
    this.startDragToCreate(segment, segmentElement)
  }

  isOverlappingEvent(event: CalendarEvent, start: Date, end: Date) {
    return this.events.find((otherEvent) => {
      return (
        otherEvent !== event && !otherEvent.allDay &&
        ((otherEvent.start < start && start < otherEvent.end) || (otherEvent.start < end && start < otherEvent.end))
      );
    });
  }

  validateAfterCreateAndDrag() {
    this.events.forEach(event=> {
      const overlappingEvent = this.isOverlappingEvent(event, event.start, event.end);
      if (overlappingEvent || event.end > this.rangeLimit) {
        this.events = this.events.filter((iEvent) => iEvent !== event);
      } else {
        this.mergeEventsIfNeeded(event);
        event.title = this.getTitle(event.start, event.end);
        this.splitEventIfNeeded(event)
      }
    });
  }

   resetDateHours(date: Date, addDays: number) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + addDays, 0, 0, 0)
  }

  createEvent(startDate:Date, endDate:Date):CalendarEvent {
    return {
      id: this.events.length,
      title: this.getTitle(startDate, endDate),
      start: startDate,
      end: endDate,
      draggable: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      meta: {
        tmpEvent: true,
      },
      actions: [
        {
          label: '<em class="bi-trash trash"></em>',
          onClick: ({ event }: { event: CalendarEvent }): void => {
            this.events = this.events.filter((iEvent) => iEvent !== event);
          },
        },
      ]
    };
  }

  getDatesBetween(startDate:Date, endDate: Date): Date[] {
    const dates = [];
    const currentDate = this.resetDateHours(new Date(startDate), 0);
    const lastDate = this.resetDateHours(new Date(endDate), 0);

    while (currentDate <= lastDate) {
      let date = new Date(currentDate.getDate() == startDate.getDate() ? startDate :
        currentDate.getDate() == endDate.getDate() ? endDate : currentDate);
      dates.push(date)
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  mergeEventsIfNeeded(event: CalendarEvent) : boolean {
    let eventForMerge = this.isEventForMerge(event);
    if (eventForMerge) {
      if (event.end.getTime() == eventForMerge.start.getTime()) {
        event.end = eventForMerge.end;
      } else {
        event.start = eventForMerge.start;
      }
      event.title = this.getTitle(event.start, event.end)
      this.deleteEvent(eventForMerge)
    }
    return !!eventForMerge;
  }

  getTitle(dateFrom: Date, dateTo: Date) {
    return formatDate(dateFrom, 'HH:mm') + " - " + formatDate(dateTo, 'HH:mm');
  }

  startDragToCreate (segment: WeekViewHourSegment, segmentElement: HTMLElement) {
    let dragToSelectEvent: CalendarEvent = this.createEvent(segment.date, addMinutes(segment.date, 60));
    if (this.isAnotherDay(dragToSelectEvent.start, dragToSelectEvent.end)) {
      return
    }
    this.events = [...this.events, dragToSelectEvent];
    this.mergeEventsIfNeeded(dragToSelectEvent);
    const segmentPosition = segmentElement.getBoundingClientRect();
    this.dragToCreateActive = true;

    fromEvent(document, 'mousemove').pipe(
      finalize(() => {
        delete dragToSelectEvent.meta.tmpEvent;
        this.dragToCreateActive = false;
        this.validateAfterCreateAndDrag()
        this.refresh();
      }),
      takeUntil(fromEvent(document, 'mouseup'))
    ).subscribe((mouseMoveEvent: MouseEvent) => {
      let minutesDiff = ceilToNearest(
        mouseMoveEvent.clientY - segmentPosition.top,
        30
      );

      const daysDiff =
        floorToNearest(
          mouseMoveEvent.clientX - segmentPosition.left,
          segmentPosition.width
        ) / segmentPosition.width;

      if (minutesDiff == 30) {
        minutesDiff += 30;
      }
      const newEnd = addDays(addMinutes(segment.date, minutesDiff), daysDiff);
      if (newEnd > dragToSelectEvent.start && this.resetDateHours(this.viewDate, this.weekDays) >= newEnd) {
        dragToSelectEvent.end = newEnd;
        if (this.getMinuteDifference(dragToSelectEvent.end, dragToSelectEvent.start) == 30) {
          dragToSelectEvent.end = addMinutes(dragToSelectEvent.end, 30)
        }
      }
      dragToSelectEvent.title = this.getTitle(dragToSelectEvent.start, dragToSelectEvent.end);
      let overlappingEvent = this.isOverlappingEvent(dragToSelectEvent, dragToSelectEvent.start, dragToSelectEvent.end);
      if (overlappingEvent || newEnd > this.rangeLimit) {
        dragToSelectEvent.cssClass = 'invalid-position';
      } else {
        delete dragToSelectEvent.cssClass;
      }
      this.refresh();
    });
  }

  refresh() {
    this.events = [...this.events];
    this.cdr.detectChanges();
  }

  refreshAfterDrug = new Subject<void>();

  eventTimesChanged(eventTimesChangedEvent: CalendarEventTimesChangedEvent): void {
    delete eventTimesChangedEvent.event.cssClass;
    const { event, newStart, newEnd } = eventTimesChangedEvent;
    if (this.validateEventTimesChanged(eventTimesChangedEvent, false)) {
      if (this.isAnotherDay(newStart, newEnd)) {
        let separateDate = this.resetDateHours(new Date(newEnd), 0);
        if (this.getMinuteDifference(separateDate, newStart)  < 60 || this.getMinuteDifference(newEnd, separateDate) < 60) {
          delete eventTimesChangedEvent.event.cssClass;
          event.title = this.getTitle(event.start, event.end);
          this.refreshAfterDrug.next();
          return;
        }
      }

      if (this.getMinuteDifference(newEnd, newStart) <= 30) {
        this.refreshAfterDrug.next();
        return;
      }
      event.start = newStart;
      event.end = newEnd;
      let isMerge = this.mergeEventsIfNeeded(event);
      //if merged then need to double-check because shifted event we can place between two events
      if (isMerge) {
        this.mergeEventsIfNeeded(event)
      }
    }

    event.title = this.getTitle(event.start, event.end);
    this.splitEventIfNeeded(event);
    this.refreshAfterDrug.next();
  }

  getMinuteDifference(end: Date, start: Date) {
    return (end.getTime() -  start.getTime()) / 1000 / 60
  }

  splitEventIfNeeded(event: CalendarEvent) {
    if (this.isAnotherDay(event.start, event.end)) {
      let events = [];
      let datesBetween = this.getDatesBetween(event.start, event.end);
      datesBetween.forEach(date => {
        let endDate = date.getDate() == event.end.getDate() ? event.end : this.resetDateHours(date, 1);
        let startDate = date.getDate() == event.start.getDate() ? date : this.resetDateHours(date, 0);
        events.push(this.createEvent(startDate, endDate))
      })
      this.deleteEvent(event);
      events.forEach(event => {
        this.events = [...this.events, event];
        this.mergeEventsIfNeeded(event)
      })
    }
  }

  isAnotherDay(start: Date, end: Date) {
    return start.getDate() != end.getDate() && (end.getHours() > 0 || end.getMinutes() > 0)
  }

  validateEventTimesChanged = (
    { event, newStart, newEnd }: CalendarEventTimesChangedEvent,
    addCssClass = true
  ) => {
    delete event.cssClass;

    if (this.getMinuteDifference(newEnd, newStart) <= 30 || (this.weekDays == 3 && this.isAnotherDay(newStart, newEnd))) {
      return false;
    }

    // don't allow dragging events to the same times as other events
    let overlappingEvent = this.isOverlappingEvent(event, newStart, newEnd);
    if (overlappingEvent || newStart < new Date() || this.isAnotherDayWithViolation(newStart, newEnd) || this.rangeLimit < newEnd) {
      if (addCssClass) {
        event.cssClass = 'invalid-position';
      } else {
        return false;
      }
    }
    event.title = this.getTitle(newStart, newEnd)
    return true;
  };

  isAnotherDayWithViolation(newStart: Date, newEnd: Date): boolean {
    const separateDate = this.resetDateHours(new Date(newEnd), 0);
    return this.isAnotherDay(newStart, newEnd) &&
      (this.getMinuteDifference(separateDate, newStart) < 60 || this.getMinuteDifference(newEnd, separateDate) < 60);
  }

  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          if (segment.date < new Date() || segment.date >= this.rangeLimit) {
            segment.cssClass = 'disabled-date';
          }
        });
      });
    });
  }

  convertToHeaderDate(date: string):Date {
    return new Date(date);
  }

  convertToHeaderDateStr(dateStr: string):string {
    let date = new Date(dateStr);
    let hours = date.getHours()
    let minutes = date.getMinutes()
    return  (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
  }

  getEndDate():Date {
    let endDate = new Date(this.viewDate);
    endDate.setDate(endDate.getDate() + this.weekDays - 1);
    return endDate;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let weekDays = window.innerWidth <= 890 ? 3 : 7;
    if (weekDays != this.weekDays) {
      this.weekDays = weekDays;
      this.viewDate = this.weekDays == 7 ?
        isMonday(new Date()) ? new Date() : previousMonday(new Date())
        : new Date();
      if (this.weekDays != 3) {
        this.collapsed = true;
      }
    }
  }

  getEventsMap():Map<number,CalendarEvent[]> {
    this.events.sort(function (a, b) {
      return a.start.getTime() - b.start.getTime();
    })

    return this.events.reduce((map, event) => {
      const key = new Date(event.start);
      key.setHours(0, 0, 0, 0);
      const keyTime = key.getTime();

      if (!map.has(keyTime)) {
        map.set(keyTime, [event]);
      } else {
        map.get(keyTime).push(event);
      }
      return map;
    }, new Map())
  }

  getWeekDay(day: number){
    let options = {weekday:window.innerWidth <= 890 ? 'short' : 'long'};
    return new Date(day).toLocaleString('en-us', <Object>options);
  }

  getWeekDayLong(day: number){
    return new Date(day).toLocaleString('en-us', {weekday: 'long'});
  }

  deleteEvent(event: CalendarEvent) {
    this.events = this.events.filter((iEvent) => iEvent !== event);
  }

  scrollToTop() {
    if (!this.collapsed) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  saveEvents() {
    this.inProgress = true;
    let candidateSlots = this.convertEventsToCandidateSlots();
    this.candidateStateService.saveEvents(candidateSlots).pipe(
        finalize(() => {
          this.inProgress = false;
          this.collapsed = true;
          this.cdr.detectChanges();
          localStorage.setItem("events", JSON.stringify(candidateSlots));
        }),
      takeUntil(this.destroy)
    ).subscribe({
        next: ()=> {
        this.openModal("finish-work")
      }, error: (error) => {
        let errors = <ErrorDTO[]>error.error.errors;
        errors.forEach(e=> this.alert.error(e.errorMessage, ''));
      }
    })
  }

  convertEventsToCandidateSlots(): CandidateSlotsDto {
    const candidateSlots = new CandidateSlotsDto();
    candidateSlots.slots = [];
    candidateSlots.candidateId = this.candidateInfo.candidateId;
    this.events.forEach(event => candidateSlots.slots.push(new EventDto(event.start, event.end)))
    return candidateSlots;
  }

  isEventForMerge(event: CalendarEvent) {
    return this.events.find((otherEvent) =>
      otherEvent !== event && event.start.getDate() == otherEvent.start.getDate() &&
      (otherEvent.start.getTime() == event.end.getTime() || otherEvent.end.getTime() == event.start.getTime())
    );
  }

  isNotPastWeek() {
    let today = this.resetDateHours(new Date(), 0);
    let startWeek = this.resetDateHours(new Date(this.viewDate), -this.weekDays);
    let endWeek = this.resetDateHours(new Date(this.viewDate), -1);
    return startWeek.getTime() >= today.getTime() || endWeek >= today;
  }

  isNotAfterLimitWeek() {
    let nextWeekFirstDay = this.weekDays == 7 ? new Date(addWeeks(this.viewDate, 1)) :
      new Date(addDays(this.viewDate, 3));
    return this.rangeLimit > nextWeekFirstDay;
  }

  touchstart() {
    this.styleElement.innerHTML = `.ng-star-inserted { touch-action: none; }`;
  }

  touchend() {
    this.styleElement.innerHTML = `.ng-star-inserted { touch-action: auto; }`;
  }

  private logCandidatesAttributes() {
    this.candidateStateService.logUser(this.candidateInfo.candidateId,
      this.isMobileUserAgent(), window.innerWidth, window.innerHeight);
  }

  isMobileUserAgent(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /android|iPad|iPhone|iPod|BlackBerry|Windows Phone|webOS|Opera Mini/i.test(userAgent);
  }
}
