<div class="container">
  <div *ngIf="type == 'start-work'">
    <div class="row slot-header">
      <div class="col-11 title">How to set your Schedule</div>
      <div class="col-1 close"><span (click)="close()">x</span></div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>Hi {{ name }}!<br>
          Here you can indicate what time you have available for an interview at Luxoft.<br>
          Just select the slots and click Submit when you're done.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <span class="image">
          <img style="width: 100%" src="/assets/images/slot-without-point.svg" alt="">
        </span>
      </div>
      <div class="col-7 image-text">
        <p style="font-size: 13px">Set a time slot and easily change it if need.</p>
      </div>
    </div>
    <div class="row" style="padding-top: 15px">
      <div class="col-12">
        <p>Looking forward to meet with you! 🙂</p>
      </div>
    </div>
    <div class="row" style="padding: 15px 0; float: right">
      <div class="col-12">
        <button (click)="close()" class="btn btn-primary">
          Got it
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="type == 'finish-work'">
    <div class="row slot-header">
      <div class="col-11 title">Your Schedule has been submitted!</div>
      <div class="col-1 close"><span (click)="close()">x</span></div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>{{ name }}, thank you for providing available timeslots for an interview!<br><br>
          This information has been saved and sent to your recruiter. We have also sent you an email confirming the available timeslots you have provided.<br><br>
          In the event of changes to your schedule, please feel welcome to update your availability via the link provided in our confirmation email.</p>
      </div>
    </div>
    <div class="row" style="padding-top: 15px">
      <div class="col-12">
        <p>Looking forward to meet with you! 🙂</p>
      </div>
    </div>
    <div class="row" style="padding: 15px 0; float: right">
      <div class="col-12">
        <button (click)="close()" class="btn btn-primary">
          Thank you!
        </button>
      </div>
    </div>
  </div>
</div>
