import { Routes } from '@angular/router';
import {CandidateSlotsComponent} from "./candidate-slots/candidate-slots.component";
import {slotsGuard} from "./guard/slots.guard";
import {ErrorComponent} from "./error/error.component";

export const routes: Routes = [
  {path: '', component: CandidateSlotsComponent, canActivate: [slotsGuard]},
  {path: 'error', component: ErrorComponent},
  {path: '**', redirectTo: ''}
];

