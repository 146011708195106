import {Component} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-slots-modal',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './slots-modal.component.html',
  styleUrl: './slots-modal.component.css'
})
export class SlotsModalComponent {
  name: string;
  type: string;

  constructor(private bsModalRef: BsModalRef) {
  }

  close() {
    this.bsModalRef.content.callback()
    this.bsModalRef.hide();
  }
}
