import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IdTokenService {

  constructor() { }
  isJwtToken(token: string): boolean {
    const parts = token.split('.');
    return parts.length === 3; // JWT tokens have exactly 3 parts separated by dots
  }

  public isTokenExpired(token: string): boolean {
    if (!this.isJwtToken(token)) {
      return true; // Not a valid JWT token
    }

    try {
      const decoded = this.getDecodedToken(token)
      if (decoded.exp < Date.now() / 1000) {
        // Token is expired
        return true;
      } else {
        // Token is not expired
        return false;
      }
    } catch (error) {
      // Error decoding token or token is malformed
      return true;
    }
  }

  public getDecodedToken(token: string) {
    return JSON.parse(window.atob(token.split('.')[1]));
  }
}
