import { Component } from '@angular/core';
import {CalendarCommonModule, CalendarView} from "angular-calendar";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [
    CalendarCommonModule,
    DatePipe
  ],
  templateUrl: './error.component.html',
  styleUrl: './error.component.css'
})
export class ErrorComponent {

  protected readonly calendarView = CalendarView;
}
