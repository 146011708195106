import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {provideAnimations} from "@angular/platform-browser/animations";
import {CalendarModule, DateAdapter} from "angular-calendar";
import {adapterFactory} from "angular-calendar/date-adapters/date-fns";
import {PositioningService} from "ngx-bootstrap/positioning";
import {ComponentLoaderFactory} from "ngx-bootstrap/component-loader";
import {BsModalService, ModalModule} from "ngx-bootstrap/modal";
import {authInterceptor} from "./interceptor/auth.interceptor";
import {provideToastr} from "ngx-toastr";

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes),
    provideToastr(),
    provideAnimations(),
    importProvidersFrom(
      CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory}),
      ModalModule.forRoot(),
      BsModalService,
      ComponentLoaderFactory,
      PositioningService
    ),
    provideHttpClient(withInterceptors([authInterceptor])),
  ]
};
